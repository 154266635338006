import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/prayer-walk-event/prayer-walk-event.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState } from 'react-jhipster';
import { useOrganisationHasCancelledSubscription, useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  VStack,
  Text,
  chakra,
  Link as ChakraLink,
  Badge,
  HStack,
  LinkBox,
  IconButton,
  LinkOverlay,
  useRadioGroup,
  useRadio,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { CheckCircleIcon, ChevronRightIcon, EditIcon } from '@chakra-ui/icons';
import { ButtonLink } from 'app/shared/components/button-link';
import { Pagination } from 'app/shared/components/pagination';
import { getCheckoutSession } from 'app/modules/organisation/reducers/checkout-session.reducer';

type BillingPeriod = 'Monthly' | 'Yearly';

export const RenewSubscription = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const organisation = useUsersOrganisation();
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>('Monthly');

  return (
    <VStack spacing={8}>
      <PricingHeader
        onBillingPeriodChange={newBillingPeriod => {
          setBillingPeriod(newBillingPeriod);
        }}
      />
      <PricingTable billingPeriod={billingPeriod} />
    </VStack>
  );
};

interface IPricingHeaderProps {
  onBillingPeriodChange: (newBillingPeriod: BillingPeriod) => void;
}

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="md"
        fontWeight="medium"
        _checked={{
          bg: 'primary.500',
          color: 'white',
          boxShadow: 'md',
        }}
        px={8}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

const PricingHeader = ({ onBillingPeriodChange }: IPricingHeaderProps) => {
  const options: Array<BillingPeriod> = ['Monthly', 'Yearly'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'billingPeriod',
    defaultValue: 'Monthly',
    onChange: onBillingPeriodChange,
  });

  const group = getRootProps();

  return (
    <VStack alignItems="center" spacing={8}>
      <Heading as="h1">Choose your plan</Heading>
      <Box display="inline-flex" borderRadius="md" bg="gray.200" alignItems="center" p={1} {...group}>
        {options.map(value => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </Box>
    </VStack>
  );
};

interface IPricingTableProps {
  billingPeriod: BillingPeriod;
}

export const PricingTable = ({ billingPeriod }: IPricingTableProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.checkoutSession.loading);
  const checkoutSessionUrl = useAppSelector(state => state.checkoutSession.checkoutSession.url);

  useEffect(() => {
    if (checkoutSessionUrl) {
      window.location.href = checkoutSessionUrl;
    }
  }, [checkoutSessionUrl]);

  const onClickSubscribe = useCallback(
    subscriptionLevel => {
      dispatch(getCheckoutSession({ billingPeriod, subscriptionLevel }));
    },
    [billingPeriod]
  );

  return (
    <HStack alignItems="center" bg="white" borderRadius="md" id="pricingTable">
      <VStack p={8} alignItems="flex-start" spacing={3}>
        <Box fontWeight="bold" fontSize="xl" mb={8}>
          Premium
        </Box>
        <HStack alignItems="flex-end">
          <Box fontWeight="bold" fontSize="4xl" lineHeight={1}>
            {billingPeriod === 'Monthly' ? '£50' : '£500'}
          </Box>
          <Box color="gray.400">per {billingPeriod === 'Monthly' ? 'month' : 'year'}</Box>
        </HStack>
        <Button
          borderRadius="md"
          w="full"
          colorScheme="primary"
          color="white"
          isLoading={loading}
          onClick={() => onClickSubscribe('PREMIUM')}
        >
          Subscribe
        </Button>
        <List spacing={2} py={2} fontWeight="medium" fontSize="sm">
          <ListItem>This includes:</ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="gray.500" />
            Heatmap of prayer walks
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="gray.500" />
            Prayer needs
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="gray.500" />
            Progress and participants page
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="gray.500" />
            One prayer walk event active at a time
          </ListItem>
        </List>
      </VStack>
    </HStack>
  );
};
