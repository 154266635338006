import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams, useOutletContext } from 'react-router-dom';
import _ from 'lodash';

import axios, { AxiosResponse } from 'axios';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import {
  Box,
  Flex,
  Image,
  VStack,
  Text,
  Stack,
  InputLeftAddon,
  InputRightAddon,
  Button,
  HStack,
  useRadioGroup,
  useRadio,
  SimpleGrid,
  Checkbox,
  Collapse,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  AlertIcon,
  Alert,
  Input,
} from '@chakra-ui/react';
import { IPrayerWalkEvent, IPrayerWalkEventSettings } from 'app/shared/model/prayer-walk-event.model';
import {
  createEntity,
  getEntity,
  reset as resetPWE,
  resetUpdate,
  updateEntity,
} from 'app/entities/prayer-walk-event/prayer-walk-event.reducer';
import {
  createEntity as createPrayerEventChallenge,
  updateEntity as updatePrayerEventChallenge,
  deleteEntity as deletePrayerEventChallenge,
  PrayerEventChallengeState,
  getEntities as getPrayerEventChallenges,
  resetById,
} from 'app/entities/prayer-event-challenge/prayer-event-challenge.reducer';
import { DeleteIcon, InfoIcon, EditIcon } from '@chakra-ui/icons';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import MapGL, { useControl, ViewStateChangeEvent, ControlPosition } from 'react-map-gl';
import MapboxDraw, { DrawCreateEvent, DrawUpdateEvent, DrawDeleteEvent, MapboxDrawOptions } from '@mapbox/mapbox-gl-draw';
import combine from '@turf/combine';
import flatten from '@turf/flatten';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { FormCheckbox, FormField, FormFileUpload, FormTextArea } from 'app/shared/components/form-field';
import { AvailableDTO } from 'app/modules/organisation/reducers/available-subdomain.reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';
import { uploadPrayerWalkEventLogo } from 'app/modules/organisation/reducers/prayer-walk-event-logo.reducer';
import { uploadPrayerWalkEventFavicon } from 'app/modules/organisation/reducers/prayer-walk-event-favicon.reducer';
import { subscriptionLevelIsPremiumOrAbove } from 'app/shared/model/enumerations/subscription-level.model';
import { IStepConfig, PrayerWalkEventStepper, StepsAsTabs } from 'app/shared/components/prayerWalkEventStepper';
import { usePolling } from 'app/shared/hooks/usePolling';
import { MapContextValue } from 'react-map-gl/dist/esm/components/map';
import { IPrayerEventChallenge } from 'app/shared/model/prayer-event-challenge.model';

interface FormValues {
  id?: number | null;
  name?: string | null;
  subdomain?: string | null;
  geoArea?: string | null;
  logoUrl?: string | null;
  mapboxStyleId?: string | null;
  mapboxTilesetId?: string | null;
  enabled?: boolean | null;
  boundary?: string | null;
  settings?: IPrayerWalkEventSettings;
  organisation?: IOrganisation;
}

interface IsStepDone {
  isDone: (pwe: IPrayerWalkEvent) => boolean;
}

const prayerWalkEventStepConfigs: Array<IStepConfig & IsStepDone> = [
  {
    title: 'Event details',
    url: 'details',
    isDone: (pwe: IPrayerWalkEvent) => pwe.settings && pwe.settings.creationState && pwe.settings.creationState.detailsFormCompleted,
  },
  {
    title: 'Branding',
    url: 'brand',
    isDone: (pwe: IPrayerWalkEvent) =>
      pwe.settings &&
      pwe.settings.creationState &&
      pwe.settings.creationState.brandingFormCompleted &&
      pwe.settings.creationState.logoFormCompleted,
  },
  {
    title: 'Map',
    url: 'map',
    isDone: (pwe: IPrayerWalkEvent) => pwe.settings && pwe.settings.creationState && pwe.settings.creationState.mapFormCompleted,
  },
  {
    title: 'Features',
    url: 'features',
    isDone: (pwe: IPrayerWalkEvent) => pwe.settings && pwe.settings.creationState && pwe.settings.creationState.featuresFormCompleted,
  },
  {
    title: 'Launch',
    url: 'launch',
    isDone: (pwe: IPrayerWalkEvent) => pwe.settings && pwe.settings.creationState && pwe.settings.creationState.launchFormCompleted,
  },
];

const prayerWalkEventHasBeenFullyCreated = (prayerWalkEvent: IPrayerWalkEvent) => {
  return prayerWalkEventStepConfigs.every(stepConfig => stepConfig.isDone(prayerWalkEvent));
};

export const PrayerWalkEventUpdate = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const prayerWalkEventEntity: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);
  const [boundary, setBoundary] = useState(prayerWalkEventEntity.boundary);
  const organisation = useUsersOrganisation();

  useEffect(() => {
    if (isNew) {
      dispatch(resetPWE());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  const defaultPrayerWalkEvent: FormValues = {
    mapboxStyleId: 'unused',
    mapboxTilesetId: 'unused',
    enabled: false,
    organisation: {
      id: organisation.id,
    },
    settings: {
      colours: {
        earmarkedColour: {
          code: '#dd0d0d',
          name: 'red',
        },
        completedColour: {
          code: '#30297f',
          name: 'blue',
        },
        branding: {
          primary: '#30297f',
          textPrimary: '#f2f2f2',
        },
      },
      logos: {
        colour: '#30297F',
        backgroundColour: '#ffffff',
      },
      features: {
        socialLoginEnabled: true,
        editableBoundaryEnabled: true,
        heatmapEnabled: false,
        participantsListEnabled: true, //subscriptionLevelIsPremiumOrAbove(organisation),
        prayerNeedsEnabled: true, //subscriptionLevelIsPremiumOrAbove(organisation),
        prayerEventChallengesEnabled: false,
        featureTourEnabled: false,
      },
      mapStyle: {
        heatmapLineColourIsRelative: null,
        numOfWalksRequiredForHighestColour: 10,
        colourStops: [
          {
            decimalPercentage: 0,
            colour: '#dd0d0d',
            colourName: 'red',
          },
          {
            decimalPercentage: 1,
            colour: '#30297f',
            colourName: 'blue',
          },
        ],
      },
      links: {
        externalLinks: [],
        contactUs: 'mailto:support@prayerwalk.app',
      },
      domains: [],
      content: {
        preHowDoIGetStartedMarkdown:
          '## Why prayer walking?\nWe believe that God is able and willing to transform where we live. As His children, we are able to ask Him simply to do this, praying: Your Kingdom Come! In the current season, there has never been a better time for the church to unite together in bringing our neighbourhoods, towns and cities before God, street by street. We believe He will hear and answer us and we will see much-needed blessing in our local communities.\n\n## What is a prayer walk?\n\nPrayer walking is as simple as it sounds – praying as you walk. Prayer walking is a low-key affair. It’s not about being seen praying, it’s about seeing and praying: be on the scene without making a scene! You will need to keep your eyes and ears open, responding to your environment in quiet conversation with God.\n',
        postConfirmingYourRouteIsCompletedMarkdown: 'Thank you for playing your part!',
        disabledMessage: 'This prayer walking event is finished, you can still look around but you cannot change or add any walks.',
        homeScreenBackground: {
          type: 'VIDEO',
          url: 'content/video.mp4',
        },
      },
      initialViewport: {
        longitude: 1,
        latitude: 1,
        zoom: 1,
      },
    },
  };

  const useFormMethods = useForm({ mode: 'onTouched', defaultValues: defaultPrayerWalkEvent });
  const { handleSubmit, reset } = useFormMethods;

  useEffect(() => {
    if (!isNew) {
      setBoundary(prayerWalkEventEntity.boundary);
      reset({
        ...prayerWalkEventEntity,
        boundary:
          prayerWalkEventEntity.boundary instanceof Object
            ? JSON.stringify(prayerWalkEventEntity.boundary)
            : prayerWalkEventEntity.boundary,
      });
    }
  }, [prayerWalkEventEntity]);

  const saveEntity = handleSubmit((values: FormValues) => {
    const entity = {
      ...prayerWalkEventEntity,
      ...values,
      boundary: typeof values.boundary === 'string' ? JSON.parse(values.boundary) : values.boundary,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  });

  return (
    <VStack w="full">
      {!isNew && prayerWalkEventEntity && prayerWalkEventHasBeenFullyCreated(prayerWalkEventEntity) ? (
        <StepsAsTabs stepConfigs={prayerWalkEventStepConfigs} />
      ) : (
        <PrayerWalkEventStepper stepConfigs={{ configs: prayerWalkEventStepConfigs }} />
      )}
      <Box p={8} bg="white" borderRadius="md" w="full">
        <FormProvider {...useFormMethods}>
          <form>
            <Outlet context={{ saveEntity, isNew }} />
          </form>
        </FormProvider>
      </Box>
    </VStack>
  );
};

type ExtraFormContext = {
  saveEntity: () => Promise<PayloadAction<AxiosResponse<IPrayerWalkEvent, any>, string>>;
  isNew: boolean;
};

export function useOutletContextSavingPWE() {
  return useOutletContext<ExtraFormContext>();
}

export const PrayerWalkEventUpdateStepNavigator = () => {
  const { isNew } = useOutletContextSavingPWE();
  const prayerWalkEventEntity: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);
  const navigate = useNavigate();

  useEffect(() => {
    if (isNew) {
      navigate('details');
    } else {
      if (prayerWalkEventEntity) {
        const unfinishedStepConfig = _.find(prayerWalkEventStepConfigs, stepConfig => {
          return !stepConfig.isDone(prayerWalkEventEntity);
        });
        if (unfinishedStepConfig) {
          navigate(unfinishedStepConfig.url);
        } else {
          navigate('details');
        }
      }
    }
  }, [prayerWalkEventEntity]);

  return <LoadingSpinner />;
};

interface IBackAndNextProps {
  onClickNext: () => void;
  updating: boolean;
  nextButtonText?: string;
  backUrl: string;
}

export const BackAndNext = (props: IBackAndNextProps) => {
  const navigate = useNavigate();
  const prayerWalkEventEntity: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);

  return (
    <Stack direction="row" spacing={2} w="full">
      <Button
        size="lg"
        onClick={() => {
          if (prayerWalkEventHasBeenFullyCreated(prayerWalkEventEntity)) {
            navigate(`../../prayer-walk-events/${prayerWalkEventEntity.id}`);
          } else {
            navigate(props.backUrl);
          }
        }}
        id="back"
      >
        {prayerWalkEventHasBeenFullyCreated(prayerWalkEventEntity) ? 'Close' : 'Back'}
      </Button>
      <Button flex={1} w="full" colorScheme="primary" size="lg" onClick={props.onClickNext} isLoading={props.updating} id="save">
        {props.nextButtonText || (prayerWalkEventHasBeenFullyCreated(prayerWalkEventEntity) ? 'Save' : 'Next')}
      </Button>
    </Stack>
  );
};

export const PrayerWalkEventUpdateStepOneDetails = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const dispatch = useAppDispatch();
  const { saveEntity, isNew } = useOutletContextSavingPWE();
  const navigate = useNavigate();
  const prayerWalkEventEntity: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);
  const updateSuccess = useAppSelector(state => state.prayerWalkEvent.updateSuccess);
  const updating = useAppSelector(state => state.prayerWalkEvent.updating);
  const [sentUpdateRequest, setSentUpdateRequest] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetUpdate());
  }, []);

  useEffect(() => {
    if (sentUpdateRequest && updateSuccess) {
      if (!prayerWalkEventHasBeenFullyCreated(prayerWalkEventEntity)) {
        navigate(`/organisation/prayer-walk-events/${prayerWalkEventEntity.id}/edit/brand`);
      }
    }
  }, [prayerWalkEventEntity, updateSuccess]);

  const saveAndSetSent = () => {
    saveEntity().then(() => {
      setSentUpdateRequest(true);
    });
  };

  return (
    <Flex rowGap={6} direction="column" alignItems="center" w="full">
      <Checkbox {...register('settings.creationState.detailsFormCompleted')} defaultChecked display="none" />
      <FormField
        label="Name of the prayer walk"
        errors={errors}
        {...register('name', {
          required: 'Name cannot be empty',
          validate: {
            async checkName(name: string) {
              const requestUrl = `/api/prayer-walk-events/available-name`;
              const res = await axios.post<AvailableDTO>(requestUrl, {
                name,
                prayerWalkEventId: prayerWalkEventEntity && prayerWalkEventEntity.id,
              });
              return (
                res.data.available ||
                'This name is already being used, if you already own the other prayer walk event using it, then change that name to something else first.'
              );
            },
          },
        })}
      />
      <FormField
        label="Your url"
        errors={errors}
        {...register('subdomain', {
          required: 'Subdomain cannot be empty',
          pattern: {
            value: /^[a-z0-9-]*$/,
            message: 'url can only contain lowercase letters, numbers and dashes',
          },
          validate: {
            async checkUrl(subdomain: string) {
              const requestUrl = `/api/prayer-walk-events/available-subdomain`;
              const res = await axios.post<AvailableDTO>(requestUrl, {
                subdomain,
                prayerWalkEventId: prayerWalkEventEntity && prayerWalkEventEntity.id,
              });
              return (
                res.data.available ||
                'This url is already being used, if you already own the other prayer walk event using it, then change that first.'
              );
            },
          },
        })}
        // eslint-disable-next-line react/no-children-prop
        leftAddon={<InputLeftAddon children="https://" />}
        // eslint-disable-next-line react/no-children-prop
        rightAddon={<InputRightAddon children=".prayerwalk.app" />}
        // eslint-disable-next-line react/no-children-prop
        // rightElement={<InputRightElement right="170px" children={loading ? <LoadingSpinner/> : available ?
        //   <CheckIcon color="green.500"/> : subdomainIsNotBlank ? <NotAllowedIcon color="red.500"/> : null}/>}
        helperText="The url your prayer walk event will be available on, add this to your website for your users to access the app."
      />
      <FormField
        label="The area you're praying in"
        errors={errors}
        {...register('geoArea', {
          required: "The area you're praying for cannot be empty",
        })}
        helperText="e.g. Birmingham, The Peak District or Wakefield and the surrounding areas"
      />
      <BackAndNext onClickNext={saveAndSetSent} updating={updating} backUrl="/organisation/prayer-walk-events" />
    </Flex>
  );
};

export const PrayerWalkEventUpdateStepTwoBrand = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { saveEntity } = useOutletContextSavingPWE();
  const prayerWalkEventEntity = useAppSelector(state => state.prayerWalkEvent.entity);
  const updateSuccess = useAppSelector(state => state.prayerWalkEvent.updateSuccess);
  const navigate = useNavigate();
  const [sentUpdateRequest, setSentUpdateRequest] = useState<boolean>(false);
  const updating = useAppSelector(state => state.prayerWalkEvent.updating);

  useEffect(() => {
    if (sentUpdateRequest && updateSuccess) {
      if (!prayerWalkEventHasBeenFullyCreated(prayerWalkEventEntity)) {
        navigate(`../map`);
      }
    }
  }, [updateSuccess]);

  const saveAndSetSent = useCallback(() => {
    saveEntity().then(() => {
      setSentUpdateRequest(true);
    });
  }, [saveEntity]);

  return (
    <Flex rowGap={6} direction="column" alignItems="center" w="full">
      <Checkbox {...register('settings.creationState.brandingFormCompleted')} defaultChecked display="none" />
      <FormField
        label="Your primary branding colour"
        type="color"
        errors={errors}
        {...register('settings.colours.branding.primary', {
          required: 'Primary brand colour cannot be empty',
        })}
        p={0}
      />
      <PrayerWalkEventUpdateLogos />
      <BackAndNext onClickNext={saveAndSetSent} updating={updating} backUrl="../details" />
    </Flex>
  );
};

export const PrayerWalkEventUpdateLogos = () => {
  const dispatch = useAppDispatch();
  const { setValue, watch, register } = useFormContext();
  const prayerWalkEventEntity: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);
  const logoLoading = useAppSelector(state => state.prayerWalkEventLogo.loading);
  const uploadedLogoUrl = useAppSelector(state => state.prayerWalkEventLogo.logoUrl);
  const logoError = useAppSelector(state => state.prayerWalkEventLogo.errorMessage);
  const faviconLoading = useAppSelector(state => state.prayerWalkEventFavicon.loading);
  const faviconPreviewFileName = useAppSelector(state => state.prayerWalkEventFavicon.faviconPreviewFileName);
  const faviconError = useAppSelector(state => state.prayerWalkEventFavicon.errorMessage);
  const errors = {};
  if (logoError) {
    errors['logo-image-upload'] = { message: logoError };
  }
  if (faviconError) {
    errors['favicon-image-upload'] = { message: faviconError };
  }
  const logoUrl = watch('logoUrl');
  const faviconPreviewUrl = watch('settings.logos.faviconPreviewUrl');
  const faviconIsGenerated = useCallback((): boolean => {
    return (
      (prayerWalkEventEntity.settings &&
        prayerWalkEventEntity.settings.logos &&
        prayerWalkEventEntity.settings.logos.faviconPreviewUrl &&
        prayerWalkEventEntity.settings.logos.faviconPreviewUrl.indexOf(faviconPreviewFileName) > 0) ||
      faviconError !== null
    );
  }, [prayerWalkEventEntity, faviconPreviewFileName, faviconError]);
  const pollingCall = useCallback(() => {
    dispatch(getEntity(prayerWalkEventEntity.id));
  }, [prayerWalkEventEntity]);

  const { startPolling, isPolling } = usePolling(pollingCall, faviconIsGenerated, 'Timed out waiting for favicon to generate', 5000, 35);
  // todo stop people navigating back and forth when polling is happening, they need to wait otherwise they might unknowingly overwrite the
  // favicon data with empty values if they load an update page before it's finished and then save after it's done
  useEffect(() => {
    if (uploadedLogoUrl !== null) {
      setValue('logoUrl', uploadedLogoUrl);
    }
  }, [uploadedLogoUrl]);

  const useExampleLogo = useCallback(() => {
    setValue(
      'logoUrl',
      'https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/prayer-walk-events%2Fexample%2Flogo.png?alt=media&token=d1ad4a4a-0b0e-48f8-8726-2d8a9a0f1921'
    );
  }, []);

  const useExampleIcon = useCallback(() => {
    setValue(
      'settings.logos.faviconPreviewUrl',
      'https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/prayer-walk-events%2Fexample%2Ffavicon-preview_v1.png?alt=media&token=55af7892-b5a7-49ec-836d-aa4234687585'
    );
    setValue(
      'settings.logos.faviconFolderUrl',
      'https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/prayer-walk-events%2Fexample%2Ffavicons'
    );
  }, []);

  const uploadLogo = e => {
    e.preventDefault();
    const formData = new FormData();
    // todo make sure e.target.files[0] is not null
    formData.append('logoImage', e.target.files[0], e.target.files[0].name);
    dispatch(uploadPrayerWalkEventLogo({ formData, id: prayerWalkEventEntity.id }));
  };
  const uploadFavicon = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('faviconImage', e.target.files[0], e.target.files[0].name);
    dispatch(uploadPrayerWalkEventFavicon({ formData, id: prayerWalkEventEntity.id }));
    startPolling();
  };

  return (
    <>
      <Checkbox {...register('settings.creationState.logoFormCompleted')} defaultChecked display="none" />
      <Stack direction="column" spacing={6} w="full">
        <Stack direction="column" w="full">
          <Box>Logo</Box>
          {logoUrl && <Image maxW="sm" objectFit="contain" src={logoUrl} alt="Your prayer walk event logo" />}
          <HStack spacing={2} alignItems="flex-start">
            <FormFileUpload
              accept="image/*"
              name="logo-image-upload"
              onChange={uploadLogo}
              errors={errors}
              helperText="Must be a PNG file with a transparent background"
            >
              <Button isLoading={logoLoading} as="span" colorScheme="primary" w="full">
                Upload Logo
              </Button>
            </FormFileUpload>
            <Button onClick={useExampleLogo}>Use example logo</Button>
          </HStack>
        </Stack>
        <Stack direction="column" w="full" id="faviconUploadComponent">
          <Box>App icon</Box>
          {faviconPreviewUrl && <Image objectFit="contain" src={faviconPreviewUrl} alt="Your prayer walk event icon" />}
          <HStack spacing={2} alignItems="flex-start">
            <FormFileUpload
              accept="image/*"
              name="favicon-image-upload"
              onChange={uploadFavicon}
              errors={errors}
              helperText="Must be a square PNG or JPEG image, at least 70x70, but ideally 512x512"
            >
              <Button isLoading={faviconLoading || isPolling} as="span" colorScheme="primary" w="full">
                Upload Icon
              </Button>
            </FormFileUpload>
            <Button onClick={useExampleIcon}>Use example Icon</Button>
          </HStack>
          {(faviconLoading || isPolling) && (
            <Alert status="info" mt={2}>
              <AlertIcon />
              This can take up to 2 minutes whilst we generate the icons for all platforms, please wait for the image processing to finish
            </Alert>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export const PrayerWalkEventUpdateStepThreeMap = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const { saveEntity } = useOutletContextSavingPWE();
  const prayerWalkEventEntity = useAppSelector(state => state.prayerWalkEvent.entity);
  const updateSuccess = useAppSelector(state => state.prayerWalkEvent.updateSuccess);
  const navigate = useNavigate();
  const [sentUpdateRequest, setSentUpdateRequest] = useState<boolean>(false);
  const updating = useAppSelector(state => state.prayerWalkEvent.updating);

  useEffect(() => {
    if (sentUpdateRequest && updateSuccess) {
      if (!prayerWalkEventHasBeenFullyCreated(prayerWalkEventEntity)) {
        navigate(`../features`);
      }
    }
  }, [updateSuccess]);

  const saveAndSetSent = () => {
    saveEntity().then(() => {
      setSentUpdateRequest(true);
    });
  };

  const mapTypeOptions = [
    {
      label: 'solid lines - good if you want to cover an area at least once',
      value: 'solid',
      imgUrl: 'content/images/solid-lines-example.png',
    },
    {
      label: 'heatmap - better if streets will be prayed for multiple times',
      value: 'heatmap',
      imgUrl: 'content/images/heatmap-example.png',
    },
  ];

  const { getRootProps: getRootMapTypeProps, getRadioProps: getMapTypeRadioProps } = useRadioGroup({
    name: 'mapType',
    defaultValue: getValues('settings.features.heatmapEnabled') ? 'heatmap' : 'solid',
    onChange(choice) {
      if (choice === 'heatmap') {
        setValue('settings.features.heatmapEnabled', true);
      } else {
        setValue('settings.features.heatmapEnabled', false);
      }
    },
  });

  const mapColourOptions = [
    {
      label: 'Open Heaven',
      value: 'openheaven',
      earmarkedColour: '#cb0d0d',
      earmarkedColourName: 'red',
      completedColour: '#2408d5',
      completedColourName: 'blue',
      heatmapColours: [
        { percentage: 0.0, colour: '#cb0d0d', colourName: 'red' },
        { percentage: 1.0, colour: '#2408d5', colourName: 'blue' },
      ],
    },
    {
      label: 'Arise',
      value: 'arise',
      earmarkedColour: '#3b3b3b',
      earmarkedColourName: 'grey',
      completedColour: '#ff9f0b',
      completedColourName: 'orange',
      heatmapColours: [
        { percentage: 0.0, colour: '#ec3a21', colourName: 'red' },
        { percentage: 1.0, colour: '#fde100', colourName: 'yellow' },
      ],
    },
    {
      label: 'Vision',
      value: 'vision',
      earmarkedColour: '#464343',
      earmarkedColourName: 'grey',
      completedColour: '#5829ff',
      completedColourName: 'purple',
      heatmapColours: [
        { percentage: 0.0, colour: '#bb8eff', colourName: 'lilac' },
        { percentage: 1.0, colour: '#4800cc', colourName: 'purple' },
      ],
    },
  ];
  const completedColourCode = getValues(`settings.colours.completedColour.code`);
  let defaultMapColourValue;
  switch (completedColourCode) {
    case '#2408d5':
      defaultMapColourValue = 'Open Heaven';
      break;
    case '#ff9f0b':
      defaultMapColourValue = 'Arise';
      break;
    case '#5829ff':
      defaultMapColourValue = 'Vision';
      break;
    default:
      defaultMapColourValue = 'Open Heaven';
  }

  const { getRootProps: getRootMapColourProps, getRadioProps: getMapColourRadioProps } = useRadioGroup({
    name: 'mapColours',
    defaultValue: defaultMapColourValue,
    onChange(choice) {
      const mapColourOption = _.find(mapColourOptions, { label: choice });
      mapColourOption.heatmapColours.forEach((colourStop, index) => {
        setValue(`settings.mapStyle.colourStops.${index}.decimalPercentage`, colourStop.percentage);
        setValue(`settings.mapStyle.colourStops.${index}.colour`, colourStop.colour);
        setValue(`settings.mapStyle.colourStops.${index}.colourName`, colourStop.colourName);
      });
      setValue('settings.colours.earmarkedColour.code', mapColourOption.earmarkedColour);
      setValue('settings.colours.earmarkedColour.name', mapColourOption.earmarkedColourName);
      setValue('settings.colours.completedColour.code', mapColourOption.completedColour);
      setValue('settings.colours.completedColour.name', mapColourOption.completedColourName);
    },
  });

  const mapTypeProps = getRootMapTypeProps();
  const mapColourProps = getRootMapColourProps();

  return (
    <Flex rowGap={6} direction="column" alignItems="center" w="full">
      <Checkbox {...register('settings.creationState.mapFormCompleted')} defaultChecked display="none" />
      <Box>Map colour type</Box>
      <HStack {...mapTypeProps}>
        {mapTypeOptions.map(option => {
          const radio = getMapTypeRadioProps({ value: option.value });
          return (
            <RadioCard key={option.value} {...radio} id={option.value}>
              <Image src={option.imgUrl} maxW={96} />
              <Box mt="1" fontWeight="semibold" lineHeight="tight" maxW={96}>
                {option.label}
              </Box>
            </RadioCard>
          );
        })}
      </HStack>
      <Box>Map colours</Box>
      <SimpleGrid {...mapColourProps} columns={3} spacing={8}>
        {mapColourOptions.map(option => {
          const radio = getMapColourRadioProps({ value: option.label });
          return (
            <RadioCard key={option.label} {...radio} id={option.value}>
              <VStack w="full" spacing={3}>
                <Box>{option.label}</Box>
                <VStack w="full" spacing={1}>
                  <Box fontSize="md">Earmarked colour</Box>
                  <Box w="full" h="5px" bg={option.earmarkedColour} />
                </VStack>
                <VStack w="full" spacing={1}>
                  <Box fontSize="md">Completed colour</Box>
                  <Box w="full" h="5px" bg={option.completedColour} />
                </VStack>
                <VStack w="full" spacing={1}>
                  <Box fontSize="md">Heatmap gradient</Box>
                  <Box
                    w="full"
                    h="5px"
                    bgGradient={`linear(to-r, ${option.heatmapColours[0].colour}, ${option.heatmapColours[1].colour})`}
                  />
                </VStack>
              </VStack>
            </RadioCard>
          );
        })}
      </SimpleGrid>
      <VStack>
        <Box>Boundary</Box>
        <Box color="gray.500">
          Select the boundary of your prayer walking. All streets inside this boundary will be selectable for a prayer walk. This might be a
          city or town boundary or something custom.
        </Box>
        <Box w="full" h="full">
          <MapBoundaryEdit />
        </Box>
      </VStack>
      <BackAndNext onClickNext={saveAndSetSent} updating={updating} backUrl="../brand" />
    </Flex>
  );
};

export const PrayerWalkEventUpdateStepFourFeatures = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const { saveEntity } = useOutletContextSavingPWE();
  const updateSuccess = useAppSelector(state => state.prayerWalkEvent.updateSuccess);
  const navigate = useNavigate();
  const [sentUpdateRequest, setSentUpdateRequest] = useState<boolean>(false);
  const [addingNewPrayerEventChallenge, setAddingNewPrayerEventChallenge] = useState<boolean>(false);
  const updating = useAppSelector(state => state.prayerWalkEvent.updating);
  const prayerWalkEventEntity: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);
  const prayerEventChallenges: readonly IPrayerEventChallenge[] = useAppSelector(state => state.prayerEventChallenge.entities);

  useEffect(() => {
    if (prayerWalkEventEntity.id) {
      dispatch(getPrayerEventChallenges({ prayerWalkEventId: prayerWalkEventEntity.id }));
    }
  }, [prayerWalkEventEntity]);

  useEffect(() => {
    if (sentUpdateRequest && updateSuccess) {
      if (!prayerWalkEventHasBeenFullyCreated(prayerWalkEventEntity)) {
        navigate(`../launch`);
      }
    }
  }, [updateSuccess]);

  const saveAndSetSent = () => {
    saveEntity().then(() => {
      setSentUpdateRequest(true);
    });
  };

  return (
    <Flex rowGap={6} direction="column" alignItems="center" w="full">
      <Checkbox {...register('settings.creationState.featuresFormCompleted')} defaultChecked display="none" />
      <FormCheckbox
        errors={errors}
        {...register('settings.features.prayerEventChallengesEnabled')}
        helperText="Prayer steps are goals or tasks that you can set for your users to take on whilst they prayer walk. They will tick these off themselves in their progress area."
      >
        Enable Prayer Steps
      </FormCheckbox>
      <Box as={Collapse} in={watch('settings.features.prayerEventChallengesEnabled')} animateOpacity w="full">
        <VStack w="full" id="prayerEventChallengesList">
          {prayerEventChallenges.map((prayerEventChallenge, index) => {
            return <PrayerEventChallenge key={prayerEventChallenge.id} prayerEventChallenge={prayerEventChallenge} />;
          })}
          {!addingNewPrayerEventChallenge && (
            <Box w="full">
              <Flex grow={1} />
              <Button onClick={() => setAddingNewPrayerEventChallenge(true)}>Add new prayer step</Button>
            </Box>
          )}
          {addingNewPrayerEventChallenge && (
            <PrayerEventChallengeUpdateRow isNew={true} onSave={() => setAddingNewPrayerEventChallenge(false)} />
          )}
        </VStack>
      </Box>

      <FormCheckbox
        errors={errors}
        {...register('settings.features.featureTourEnabled')}
        helperText="A glowing dot will show on some parts of the app, which users can click to find out more. They can also skip the tour completely. This helps users understand how to use the app."
      >
        Enable tour for new users
      </FormCheckbox>
      <BackAndNext onClickNext={saveAndSetSent} updating={updating} backUrl="../map" />
    </Flex>
  );
};

const PrayerEventChallenge = ({ prayerEventChallenge }: { prayerEventChallenge: IPrayerEventChallenge }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const dispatch = useAppDispatch();
  const prayerWalkEventEntity: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);

  if (isEditing) {
    return <PrayerEventChallengeUpdateRow isNew={false} original={prayerEventChallenge} onSave={() => setIsEditing(false)} />;
  } else {
    return (
      <Stack direction="row" w="full">
        <Box flex={1}>{prayerEventChallenge.title}</Box>
        <Button onClick={() => setIsEditing(true)} leftIcon={<EditIcon />}>
          Edit
        </Button>
        <Button
          colorScheme="red"
          leftIcon={<DeleteIcon />}
          // put loading state here when deleting
          onClick={() => dispatch(deletePrayerEventChallenge({ prayerWalkEventId: prayerWalkEventEntity.id, id: prayerEventChallenge.id }))}
        >
          Delete
        </Button>
      </Stack>
    );
  }
};

interface IPrayerEventChallengeUpdateRowProps {
  isNew: boolean;
  original?: IPrayerEventChallenge;
  onSave?: () => void;
}

const PrayerEventChallengeUpdateRow = ({ isNew, original, onSave }: IPrayerEventChallengeUpdateRowProps) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(original ? original.title : '');
  const handleChange = event => setValue(event.target.value);
  const prayerWalkEventEntity: IPrayerWalkEvent = useAppSelector(state => state.prayerWalkEvent.entity);
  const idOrZero = isNew ? '0' : original.id;
  const updating: boolean = useAppSelector(
    state => state.prayerEventChallenge.byId[idOrZero] && state.prayerEventChallenge.byId[idOrZero].updating
  );
  const updateSuccess: boolean = useAppSelector(
    state => state.prayerEventChallenge.byId[idOrZero] && state.prayerEventChallenge.byId[idOrZero].updateSuccess
  );

  useEffect(() => {
    if (updateSuccess) {
      onSave();
      dispatch(resetById(idOrZero));
    }
  }, [updateSuccess, onSave]);

  const save = useCallback(() => {
    if (isNew) {
      const entity = { title: value, prayerWalkEvent: prayerWalkEventEntity };
      dispatch(createPrayerEventChallenge({ prayerWalkEventId: prayerWalkEventEntity.id, entity }));
    } else {
      const entity = {
        ...original,
        title: value,
        prayerWalkEvent: prayerWalkEventEntity,
      };
      dispatch(updatePrayerEventChallenge({ prayerWalkEventId: prayerWalkEventEntity.id, entity }));
    }
  }, [value, prayerWalkEventEntity, original]);

  return (
    <Stack direction="row" w="full">
      <Flex grow={1}>
        <Input value={value} onChange={handleChange} placeholder="Your prayer step e.g. prayer walk in a different area" />
      </Flex>
      <Button onClick={save} isLoading={updating}>
        Save
      </Button>
    </Stack>
  );
};

export const PrayerWalkEventUpdateStepFiveLaunch = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { saveEntity } = useOutletContextSavingPWE();
  const updateSuccess = useAppSelector(state => state.prayerWalkEvent.updateSuccess);
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();
  const [sentUpdateRequest, setSentUpdateRequest] = useState<boolean>(false);
  const updating = useAppSelector(state => state.prayerWalkEvent.updating);

  useEffect(() => {
    if (sentUpdateRequest && updateSuccess) {
      navigate(`/organisation/prayer-walk-events`);
    }
  }, [updateSuccess]);

  const saveAndSetSent = () => {
    saveEntity().then(() => {
      setSentUpdateRequest(true);
    });
  };

  return (
    <Flex rowGap={8} direction="column" alignItems="center" w="full">
      <Checkbox {...register('settings.creationState.launchFormCompleted')} defaultChecked display="none" />
      <VStack alignItems="flex-start" w="full">
        <FormCheckbox errors={errors} {...register('enabled')} helperText="This will deactivate any other prayer walk events.">
          Activate prayer walk event
        </FormCheckbox>
        <Button variant="link" leftIcon={<InfoIcon />} onClick={onToggle}>
          What does activating the event do?
        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Box fontSize="sm">
            When a prayer walk event is active, it can be added to. When it&apos;s inactive it&apos;s locked and no changes can be made.
            This is good for when you want to set up the event beforehand and not allow any prayer walks to be recorded before a launch
            date. On the launch date, you would return here to activate it. Once the event is done you should deactivate it.
          </Box>
        </Collapse>
      </VStack>
      <FormTextArea
        label="Message shown when event is inactive"
        errors={errors}
        {...register('settings.content.disabledMessage')}
        helperText="This is shown in a banner at the top of the map, it can let people know when the event will start or that it's already finished"
      />
      <BackAndNext onClickNext={saveAndSetSent} updating={updating} nextButtonText="Finish" backUrl="../features" />
    </Flex>
  );
};

const RadioCard = props => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" id={props.id}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="3px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: 'gray.50',
          borderColor: 'primary.500',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const MapBoundaryEdit = () => {
  const {
    register,
    unregister,
    formState: { errors },
    setValue,
    watch,
    getValues,
    resetField,
    clearErrors,
  } = useFormContext();

  const setViewport = useCallback((event: ViewStateChangeEvent) => {
    setValue('settings.initialViewport.longitude', event.viewState.longitude);
    setValue('settings.initialViewport.latitude', event.viewState.latitude);
    setValue('settings.initialViewport.zoom', event.viewState.zoom);
  }, []);

  const mapViewport = {
    longitude: watch('settings.initialViewport.longitude') || 0,
    latitude: watch('settings.initialViewport.latitude') || 0,
    zoom: watch('settings.initialViewport.zoom') || 1,
  };

  const onCreate = useCallback((e: DrawCreateEvent, mapboxDraw: MapboxDraw) => {
    resetField('boundary');
    clearErrors('boundary');
    const combinedFeatures = combine(mapboxDraw.getAll());
    setValue('boundary', JSON.stringify(combinedFeatures.features[0]));
  }, []);

  const onUpdate = useCallback((e: DrawUpdateEvent, mapboxDraw: MapboxDraw) => {
    const combinedFeatures = combine(mapboxDraw.getAll());
    setValue('boundary', JSON.stringify(combinedFeatures.features[0]));
  }, []);

  const onDelete = useCallback((e: DrawDeleteEvent, mapboxDraw: MapboxDraw) => {
    const combinedFeatures = combine(mapboxDraw.getAll());
    setValue('boundary', JSON.stringify(combinedFeatures.features[0]));
  }, []);

  const onLoad = useCallback((mapboxDraw: MapboxDraw) => {
    const boundaryJsonString = getValues('boundary');
    const boundaryGeoJSON = JSON.parse(boundaryJsonString);
    if (boundaryGeoJSON !== null) {
      // uncombine into separate features
      const fc = flatten(boundaryGeoJSON);
      mapboxDraw.set(fc);
    } else {
      // if the user hasn't drawn anything yet, let them do it straight away
      mapboxDraw.changeMode('draw_polygon');
    }
  }, []);

  useEffect(() => {
    register('boundary', { required: true });
    return () => {
      if (!getValues('boundary')) {
        // if boundary hasn't been set we should unregister it so the form isn't
        // invalid for something the user hasn't done, this happens when the user goes back and forth
        unregister('boundary');
      }
    };
  }, []);

  //todo add in local storage undo redo functionality

  return (
    <FormControl w="full" h="full" position="relative" isInvalid={errors.boundary !== undefined}>
      {errors.boundary && <FormErrorMessage mb={2}>You must draw a boundary on the map</FormErrorMessage>}
      <Box w="full" h={96}>
        <MapGL
          id="boundaryMap"
          {...mapViewport}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          onMove={setViewport}
          mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
          ref={el => ((window as any).map = el)}
          onRender={event => event.target.resize()}
        >
          <DrawControl
            position="top-left"
            displayControlsDefault={false}
            controls={{
              polygon: true,
              trash: true,
            }}
            defaultMode={'simple_select'}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onLoad={onLoad}
          />
        </MapGL>
      </Box>
    </FormControl>
  );
};

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] &
  MapboxDrawOptions & {
    position?: ControlPosition;

    onCreate?: (evt: DrawCreateEvent, mapboxDraw: MapboxDraw) => void;
    onUpdate?: (evt: DrawUpdateEvent, mapboxDraw: MapboxDraw) => void;
    onDelete?: (evt: DrawDeleteEvent, mapboxDraw: MapboxDraw) => void;
    onLoad?: (mapboxDraw: MapboxDraw) => void;
  };

const DrawControl = ({ onLoad, onCreate, onUpdate, onDelete, position, ...props }: DrawControlProps) => {
  const mapboxDraw = new MapboxDraw(props);

  const onLoadWithDraw = useCallback(() => {
    if (onLoad) {
      onLoad(mapboxDraw);
    }
  }, [mapboxDraw, onLoad, props]);

  const onCreateWithDraw = useCallback(
    (e: DrawCreateEvent) => {
      if (onCreate) {
        onCreate(e, mapboxDraw);
      }
    },
    [mapboxDraw, onCreate, props]
  );

  const onUpdateWithDraw = useCallback(
    (e: DrawUpdateEvent) => {
      if (onUpdate) {
        onUpdate(e, mapboxDraw);
      }
    },
    [mapboxDraw, onUpdate, props]
  );

  const onDeleteWithDraw = useCallback(
    (e: DrawDeleteEvent) => {
      if (onDelete) {
        onDelete(e, mapboxDraw);
      }
    },
    [mapboxDraw, onDelete, props]
  );

  useControl<MapboxDraw>(
    () => mapboxDraw,
    ({ map }: MapContextValue) => {
      map.on('draw.create', onCreateWithDraw);
      map.on('draw.update', onUpdateWithDraw);
      map.on('draw.delete', onDeleteWithDraw);
      map.on('load', onLoadWithDraw);
    },
    ({ map }: MapContextValue) => {
      map.off('draw.create', onCreateWithDraw);
      map.off('draw.update', onUpdateWithDraw);
      map.off('draw.delete', onDeleteWithDraw);
      map.off('load', onLoadWithDraw);
    },
    {
      position,
    }
  );

  return null;
};
